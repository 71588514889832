<template lang="">
  <div class="app-container">
    <h2 class="brand-color mb-20">インタビュー一覧</h2>
    <el-button class="mb-20" size="small" icon="el-icon-plus" type="primary" @click="handleCreate">追加</el-button>

    <!--- Table --->
    <el-table :key="tableKey" v-loading="dataLoading" border fit highlight-current-row style="width:100%" :data="list">
      <el-table-column label="#" align="center" width="50" type="index" :index="indexTable" />
      <el-table-column label="タイトル" min-width="200" header-align="center">
        <template #default="{ row }">
          <span class="row-inline ">{{ row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" align="center" class-name="small-padding fixed-width">
        <template #default="{ row }">
          <el-button size="mini" type="primary" @click="handleEdit(row)">表示</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">削除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination -->
    <pagination
      v-show="total > 0"
      :page="listQuery.page"
      :limit="listQuery.per_page"
      :total="total"
      class="text-right"
      @pagination="getList"
    />
  </div>
</template>
<script>
import { fetchList, deleteInterview } from '@/api/interview'
import Pagination from '@/components/Pagination'
export default {
  name: 'Interview',
  components: { Pagination },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      dataLoading: true,
      listQuery: {
        page: 1,
        per_page: 10,
        importance: undefined,
        sort: '+id'
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList(data) {
      this.dataLoading = true
      if (data) {
        this.listQuery.page = data.page
        this.listQuery.per_page = data.limit
      }
      fetchList(this.listQuery).then((response) => {
        this.list = response.data.data
        this.total = response.data.total
        // console.log(this.list)

        setTimeout(() => {
          this.dataLoading = false
        }, 0.1 * 1000)
      })
    },

    indexTable(index) {
      return (this.listQuery.page - 1) * this.listQuery.per_page + index + 1
    },

    handleCreate() {
      this.$router.push(this.$route.path + '/create')
    },

    handleEdit(row) {
      this.$router.push(this.$route.path + '/' + row.id + '/edit')
    },

    handleDelete(row) {
      this.$confirm('このデータを削除してもよろしいですか？').then(_ => {
        deleteInterview(row.id).then((response) => {
          this.$notify({
            title: 'Success',
            message: 'データは正常に削除されました',
            type: 'success',
            duration: 2000
          })
          this.dataLoading = false
          this.getList()
        })
      }).catch(_ => {})
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
